const FirebaseConfig = {
  apiKey: "AIzaSyBa_mW60GSu40WJ5iIF-Wg49KEuzw7YNKQ",
  authDomain: "inponsel-dashboard.firebaseapp.com",
  projectId: "inponsel-dashboard",
  storageBucket: "inponsel-dashboard.appspot.com",
  messagingSenderId: "1030234126039",
  appId: "1:1030234126039:web:a9ac2acec2f75b39226292",
};

export default FirebaseConfig;
