export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_BASE_URL_FS = process.env.REACT_APP_API_BASE_URL_FS;
export const API_BASE_URL_TS = process.env.REACT_APP_API_BASE_URL_TS;
export const brand = [
  { value: "0", label: "General" },
  {
    value: "127",
    label: "AccessGo",
  },
  {
    value: "53",
    label: "Acer",
  },
  {
    value: "79",
    label: "Advan",
  },
  {
    value: "87",
    label: "Ainol",
  },
  {
    value: "70",
    label: "Alcatel",
  },
  {
    value: "120",
    label: "Aldo Mobile",
  },
  {
    value: "72",
    label: "Amazon",
  },
  {
    value: "74",
    label: "Apple",
  },
  {
    value: "105",
    label: "Asiafone",
  },
  {
    value: "54",
    label: "Asus",
  },
  {
    value: "81",
    label: "Axioo",
  },
  {
    value: "135",
    label: "BenQ",
  },
  {
    value: "104",
    label: "Beyond",
  },
  {
    value: "55",
    label: "BlackBerry",
  },
  {
    value: "143",
    label: "Blackphone",
  },
  {
    value: "139",
    label: "Blaupunkt",
  },
  {
    value: "126",
    label: "Bolt",
  },
  {
    value: "116",
    label: "Cat",
  },
  {
    value: "144",
    label: "Chuwi",
  },
  {
    value: "142",
    label: "Coolpad",
  },
  {
    value: "75",
    label: "Cross",
  },
  {
    value: "92",
    label: "Cyrus",
  },
  {
    value: "57",
    label: "Dell",
  },
  {
    value: "110",
    label: "DGtel",
  },
  {
    value: "145",
    label: "Elephone",
  },
  {
    value: "84",
    label: "Esia",
  },
  {
    value: "95",
    label: "Evercoss",
  },
  {
    value: "112",
    label: "Fujitsu",
  },
  {
    value: "111",
    label: "Gigabyte",
  },
  {
    value: "146",
    label: "Gionee",
  },
  {
    value: "124",
    label: "Google",
  },
  {
    value: "123",
    label: "GooPhone",
  },
  {
    value: "96",
    label: "Haier",
  },
  {
    value: "107",
    label: "Himax",
  },
  {
    value: "148",
    label: "Honor",
  },
  {
    value: "58",
    label: "HP",
  },
  {
    value: "56",
    label: "HTC",
  },
  {
    value: "59",
    label: "Huawei",
  },
  {
    value: "152",
    label: "iCherry",
  },
  {
    value: "78",
    label: "IMO",
  },
  {
    value: "141",
    label: "Infinix",
  },
  {
    value: "130",
    label: "InFocus",
  },
  {
    value: "137",
    label: "Intel",
  },
  {
    value: "153",
    label: "Itel",
  },
  {
    value: "103",
    label: "Jolla",
  },
  {
    value: "88",
    label: "K-Touch",
  },
  {
    value: "100",
    label: "Kata",
  },
  {
    value: "151",
    label: "Ken Mobile",
  },
  {
    value: "132",
    label: "Kodak",
  },
  {
    value: "60",
    label: "Lenovo",
  },
  {
    value: "61",
    label: "LG",
  },
  {
    value: "102",
    label: "Maxtron",
  },
  {
    value: "138",
    label: "MediaTek",
  },
  {
    value: "71",
    label: "Meizu",
  },
  {
    value: "106",
    label: "Micromax",
  },
  {
    value: "62",
    label: "Microsoft",
  },
  {
    value: "77",
    label: "Mito",
  },
  {
    value: "73",
    label: "Motorola",
  },
  {
    value: "108",
    label: "Movi",
  },
  {
    value: "121",
    label: "MSI",
  },
  {
    value: "98",
    label: "Ninetology",
  },
  {
    value: "63",
    label: "Nokia",
  },
  {
    value: "155",
    label: "Nothing",
  },
  {
    value: "131",
    label: "NVIDIA",
  },
  {
    value: "113",
    label: "OnePlus",
  },
  {
    value: "86",
    label: "Oppo",
  },
  {
    value: "115",
    label: "Panasonic",
  },
  {
    value: "122",
    label: "Pantech",
  },
  {
    value: "109",
    label: "Phicomm",
  },
  {
    value: "64",
    label: "Philips",
  },
  {
    value: "97",
    label: "Pixcom",
  },
  {
    value: "149",
    label: "Poco",
  },
  {
    value: "82",
    label: "Polytron",
  },
  {
    value: "128",
    label: "Qualcomm",
  },
  {
    value: "150",
    label: "Razer",
  },
  {
    value: "147",
    label: "Realme",
  },
  {
    value: "76",
    label: "S-Nexian",
  },
  {
    value: "65",
    label: "Samsung",
  },
  {
    value: "66",
    label: "Sharp",
  },
  {
    value: "83",
    label: "Smartfren",
  },
  {
    value: "129",
    label: "Sonim",
  },
  {
    value: "67",
    label: "Sony",
  },
  {
    value: "68",
    label: "Sony Ericsson",
  },
  {
    value: "94",
    label: "SPC Mobile",
  },
  {
    value: "90",
    label: "SpeedUp",
  },
  {
    value: "93",
    label: "Tabulet",
  },
  {
    value: "154",
    label: "Tecno",
  },
  {
    value: "118",
    label: "THL",
  },
  {
    value: "80",
    label: "Ti-Phone",
  },
  {
    value: "125",
    label: "Toshiba",
  },
  {
    value: "101",
    label: "TREQ",
  },
  {
    value: "114",
    label: "V-GeN",
  },
  {
    value: "136",
    label: "VAIO",
  },
  {
    value: "89",
    label: "Venera",
  },
  {
    value: "85",
    label: "Vertu",
  },
  {
    value: "119",
    label: "Vivo",
  },
  {
    value: "134",
    label: "Wiko Mobile",
  },
  {
    value: "99",
    label: "Xiaomi",
  },
  {
    value: "117",
    label: "XOLO",
  },
  {
    value: "133",
    label: "YotaPhone",
  },
  {
    value: "140",
    label: "Yu",
  },
  {
    value: "69",
    label: "ZTE",
  },
  {
    value: "91",
    label: "Zyrex",
  },
];
export const operator = [
  {
    "value": 1,
    "label": "Axis"
  },
  {
    "value": 14,
    "label": "Biznet"
  },
  {
    "value": 15,
    "label": "CBN"
  },
  {
    "value": 16,
    "label": "First Media"
  },
  {
    "value": 17,
    "label": "Groovy"
  },
  {
    "value": 21,
    "label": "Iconnet"
  },
  {
    "value": 4,
    "label": "Indosat"
  },
  {
    "value": 18,
    "label": "MNC Play"
  },
  {
    "value": 19,
    "label": "MyRepublic"
  },
  {
    "value": 20,
    "label": "Oxygen"
  },
  {
    "value": 5,
    "label": "Smartfren"
  },
  {
    "value": 6,
    "label": "Telkom"
  },
  {
    "value": 7,
    "label": "Telkomsel"
  },
  {
    "value": 8,
    "label": "Tri"
  },
  {
    "value": 9,
    "label": "XL"
  }
]
//export const API_BASE_URL = "https://api-my.inponsel.com/";
