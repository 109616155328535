export const ADD_IMAGE_DATA = "ADD_IMAGE_DATA";
export const HP_PROS_DATA = "HP_PROS_DATA";
export const HP_CONS_DATA = "HP_CONS_DATA";
export const HP_DATA = "HP_DATA";
export const HP_1_DATA = "HP_1_DATA";
export const HP_2_DATA = "HP_2_DATA";
export const GEN_MEREK = "GEN_MEREK";
export const GEN_TIPE = "GEN_TIPE";
export const GEN_MODEL = "GEN_MODEL";
export const GEN_TAGS = "GEN_TAGS";
export const GEN_DIM_PANJANG = "GEN_DIM_PANJANG";
export const GEN_DIM_LEBAR = "GEN_DIM_LEBAR";
export const GEN_DIM_TEBAL = "GEN_DIM_TEBAL";
export const GEN_ADD_INFO = "GEN_ADD_INFO";
export const GEN_INPUT = "GEN_INPUT";
export const GEN_BOBOT = "GEN_BOBOT";
export const GEN_BOBOT_INFO = "GEN_BOBOT_INFO";
export const GEN_IMAGE = "GEN_IMAGE";
export const GEN_WARNA = "GEN_WARNA";
export const GEN_DIUMUMKAN = "GEN_DIUMUMKAN";
export const GEN_STATUS = "GEN_STATUS";
export const HP_DATA_FULL = "HP_DATA_FULL";
export const HP_DATA_UPDATE = "data.";
